<template>
  <v-container class="pt-0">
    <div
      v-if="!project"
      cols="12"
    >
      Loading project...
    </div>
    <div v-else>
      <v-row>
        <!-- <v-row style="margin-bottom: -60px;"> -->
        <v-col
          cols="auto"
          class="py-0 px-6"
        >
          <ProjectSettings
            :visible="showProjectSettings"
            @setVisible="val => showProjectSettings = val"
          />
          <p
            class="project-title display-1 text-uppercase d-flex align-center"
            style="min-height: 40px;"
          >
            {{ project.title }}
            <v-tooltip
              right
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  class="mx-2"
                  color="#bac1c7"
                  type="submit"
                  @click="showProjectSettings = true"
                  v-on="on"
                >
                  <v-icon
                    style="font-size: 22px;"
                  >
                    mdi-cog
                  </v-icon>
                </v-btn>
              </template>
              Project settings
            </v-tooltip>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
          class="px-6 pb-0 pt-0 mt-0"
        >
          <v-row>
            <v-col
              class="pb-0"
              :style="{ 'margin-top': $vuetify.breakpoint.mdAndUp ? '0px' : 0 }"
            >
              <p
                class="body-2 metal--text font-weight-light"
              >
                {{ project.description || "No description yet..." }}
              </p>
              <FileUpload
                :existing-file="project.image && project.image.content"
                :url="`${this.$baseURL}/api/projects/${project._id}/image`"
                file-type="image"
                height="260px"
                @uploaded="image => updateProjectImage(image)"
              >
                <template #footer>
                  <span>Or let us predict an image based on your project's metadata:</span>
                  <v-btn
                    outlined
                    @click="predictProjectImage"
                  >
                    Predict an image
                  </v-btn>
                </template>
              </FileUpload>
              <ProjectMeta />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          class="px-8 py-0"
        >
          <v-row
            class="justify-center"
          >
            <v-col cols="12">
              <v-row>
                <v-spacer />
                <v-col
                  cols="12"
                  class="px-0"
                >
                  <div class="title">
                    Versions
                    <v-spacer />
                  </div>
                  <v-divider class="mt-2 mb-2" />
                  <VersionList
                    v-resize="setVersionListHeight"
                    :max-height="versionListHeight"
                    :project-id="id"
                    @on-make-primary="makePrimary"
                    @on-make-primary-baseline="makePrimaryBaseline"
                    @show-form="showNewVersionForm = true"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col
              cols="12"
              md="4"
              class="d-flex justify-center pt-0 pb-6"
            >
              <v-dialog
                v-model="showNewVersionForm"
                width="500"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    large
                    elevation="0"
                    v-on="on"
                  >
                    Create new version
                  </v-btn>
                </template>
                <NewVersionForm
                  v-if="showNewVersionForm"
                  @hide="showNewVersionForm = false"
                />
              </v-dialog>
            </v-col>
            <v-spacer />
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'

import FileUpload from '@/components/modals/FileUpload'

import ProjectSettings from './ProjectSettings'
import VersionList from './VersionList'
import ProjectMeta from './ProjectMeta'
import NewVersionForm from './NewVersionForm'

import { createNamespacedHelpers, mapActions as mapGlobalActions } from 'vuex'
const { mapGetters: mapProjectGetters, mapMutations: mapProjectMutations, mapActions: mapProjectActions } = createNamespacedHelpers('projects')
const { mapActions: mapVersionActions } = createNamespacedHelpers('projects/versions')

export default {
  name: 'ProjectView',
  components: {
    ProjectSettings,
    VersionList,
    NewVersionForm,
    ProjectMeta,
    FileUpload
  },
  mixins: [clickaway],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showNewVersionForm: false,
      versionListHeight: 0,
      showProjectSettings: false,
      showExistingSiteConditions: false
    }
  },
  computed: {
    ...mapProjectGetters(['project', 'editingProjectMetadata'])
  },
  async created () {
    this.$store.commit('setIsOnboarding', false)
    if (!this.id) {
      this.$router.push('/projects')
    }
    try {
      if (!this.project || this.project._id !== this.id) {
        await this.fetchProjectById(this.id)
      }
    } catch (err) {
      this.$router.push('/projects')
      throw err
    }
    if (!this.project.location || !this.project.location.area) {
      this.$router.push(`/projects/${this.id}/location`)
      this.showSnackbar("Hey, looks like you haven't drawn a boundary for your project. Please draw one before continuing")
      return
    }
    if (!this.project.image || !this.project.image.content) {
      this.predictProjectImage()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setVersionListHeight()
    })
  },
  methods: {
    ...mapGlobalActions(['showSnackbar']),
    ...mapProjectMutations(['setProject', 'toggleEditingProjectMetadata']),
    ...mapProjectActions(['fetchProjectById', 'updateProject', 'predictProjectImage']),
    ...mapVersionActions(['fetchVersions']),

    async makePrimary (id) {
      if (this.project.activeVersion !== id) {
        try {
          await this.updateProject({
            activeVersion: id
          })
          this.fetchVersions({ id: this.project._id })
        } catch (err) {
          this.showSnackbar({ color: 'error', text: 'Unable to change active version. Please get in touch.' })
          throw err
        }
      }
    },
    async makePrimaryBaseline (id) {
      if (this.project.activeVersion !== id) {
        try {
          await this.updateProject({
            activeBaselineVersion: id
          })
          this.fetchVersions({ id: this.project._id })
        } catch (err) {
          this.showSnackbar({ color: 'error', text: 'Unable to change active version. Please get in touch.' })
          throw err
        }
      }
    },
    setVersionListHeight () {
      this.versionListHeight = Math.max(200, window.innerHeight - 290)
    },
    updateProjectImage (image) {
      this.setProject({
        ...this.project,
        image
      })
      this.showSnackbar('Successfully uploaded new project image')
    }
  }
}
</script>

<style lang="scss" scoped>
.project-title {
  input {
    // text-transform: uppercase;
    font-size: 36px;
    height: 36px;
  }
}
</style>
