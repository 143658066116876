<template>
  <v-card
    class="mt-5"
  >
    <v-card-title class="title">
      {{ versions.length === 0 ? "Let's create your first version" : 'New Version' }}
    </v-card-title>
    <v-card-text class="pt-0">
      <p class="body-2">
        Versions are used to track the data for your project.
      </p>
      <v-form
        ref="form"
        @submit.prevent="handleSubmit"
      >
        <v-radio-group
          v-model="newVersion.isDesignProposal"
          class="mt-0"
          hide-details
        >
          <v-radio
            label="Baseline Design"
            :value="false"
            color="shamrock"
            class="mb-3"
          />
          <span class="caption mb-6 metal--text">Select Baseline Design to establish a business as usual approach before applying Climate Positive Design changes.</span>
          <v-radio
            label="Design Alternative"
            :value="true"
            color="shamrock"
            class="mb-3"
          />
          <span class="caption mb-2 metal--text">Select Design Alternative to input data for a proposed design.</span>
        </v-radio-group>
        <v-text-field
          v-model="newVersion.title"
          :rules="[v => !!v || 'A version name is required']"
          required
          label="Name"
        />
        <v-textarea
          v-model="newVersion.description"
          rows="3"
          label="Description"
        />
        <v-select
          v-if="user.isAdmin"
          v-model="newVersion.cpdVersions"
          multiple
          placeholder=""
          :items="[2, 3]"
          class="pt-1 pb-0"
          small-chips
          deletable-chips
          label="Version Compatability"
        />
        <v-row no-gutters>
          <v-spacer />
          <v-btn
            dark
            large
            elevation="0"
            @click="handleSubmit"
          >
            Create
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import { createNamespacedHelpers, mapGetters as mapGlobalGetters, mapActions as mapGlobalActions } from 'vuex'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('projects/versions')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'NewVersionForm',
  data () {
    return {
      newVersion: {
        title: '',
        description: '',
        isDesignProposal: false
      }
    }
  },
  computed: {
    ...mapGetters(['versions']),
    ...mapAuthGetters(['user']),
    ...mapGlobalGetters(['cpdConfiguration'])
  },
  methods: {
    ...mapGlobalActions(['showSnackbar']),
    ...mapMutations([]),
    ...mapActions(['createVersion']),
    async handleSubmit () {
      const valid = this.$refs.form.validate()
      if (valid) {
        const version = await this.createVersion(this.newVersion)
        this.showSnackbar(`Created new version, ${version.title}. Opening version edit screen.`)
        this.$router.push({ name: 'calculator', params: { id: this.$store.getters['projects/project']._id }, query: { version: version._id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
